h1 {
  font-size: 32px;
}

form {
  margin-top: 2em;
}

form .loss {
  color: #e74c3c;
}

form .profit {
  color: #00bc8c;
}

small {
  color: darkgray;
}

.btn {
  width: 72px;
  height: 45px;
}

.no-radius {
    border-radius: 0;
}
